<div class="pageContainer">
    <div class="loginFormWrap">
        <div class="loginAppcode">
            <div  *ngIf = "resetButton">
                <img href="javascript:void(0);" (click)="clearCache()" src="../../../assets/chevronleft.svg"/>
                <a href="javascript:void(0);" (click)="clearCache()">{{'Appcode.AppCode' | translate}}</a>
                <p>{{appcode}}</p>
            </div> 
        </div><br>
        <div class="loginLogoWrap">
            <img class="loginLogo" src="../../../assets/appLogo.png" alt="appLogo" (dblclick)="clearCache()">
                      
        </div>        
        <h4 class="appTitle">{{'Login.Header' | translate}}</h4>
        <p class="loginHeader">{{'Login.Login' | translate}}</p>
        <div class="formWrap" *ngIf="!sso">
            <form *ngIf="formLogin" [formGroup]="formLogin" (ngSubmit)="login()">
                <div class="formRow">
                    <div class="formRow__Inner">
                        <input type="text" name="loginId" id="loginId" [placeholder]="'Login.LoginId' | translate"
                            formControlName="username"
                            [ngClass]="{'invalid': !formLogin.controls.username.valid && isSubmitted}" />
                        <!-- <span class="infoIcon">i</span> -->
                    </div>
                    <div class="errorText" *ngIf="!formLogin.controls.username.valid && isSubmitted">
                        {{'Login.ErrorRequired' | translate}}
                    </div>
                </div>
                <!-- <span class="infoIcon">i</span> -->
                <div class="formRow">
                    <div class="formRow__Inner">
                        <input [type]="showPassword ? 'text' : 'password'" name="password" id="password"
                            [placeholder]="'Login.Password' | translate" formControlName="password"
                            [ngClass]="{'invalid': !formLogin.controls.password.valid && isSubmitted}" />
                        <!-- <fa-icon class="eyeIcon" [ngClass]="{'showPassword': showPassword }" [icon]="faEye" *ngIf="formLogin.controls.password.value!=''" (click)="togglePassword()"></fa-icon> -->
                        <!-- <i class="gg-eye eyeIcon" [ngClass]="{'showPassword': showPassword }" *ngIf="formLogin.controls.password.value!=''" (click)="togglePassword()"></i> -->
                    </div>
                    <div class="errorText" *ngIf="!formLogin.controls.password.valid && isSubmitted">
                        {{'Login.ErrorRequired' | translate}}
                    </div>
                </div>
                <div class="formRow mt15">
                    <input type="submit" class="button loginButton" [ngClass]="{'disabled': disabled}"
                        name="loginButton" id="loginButton" [value]="'Login.Login' | translate" [disabled]="disabled" />
                    <!-- <span class="infoIcon">i</span> -->
                </div>
            </form>
        </div>
        
        <!-- <p class="assistanceText mt15">
            <span class="infoIcon">i</span>
            <span class="assistanceTextContent ml5">
                Contact your program administrator for assistance
            </span>
        </p> -->
        <div class="otlkToast error" *ngIf="errorMessage && errorMessage!=''">
            <div class="otlkToastInner">
                <div class="otlkToastClose">
                    <i class="gg-close otlkToastCloseIcon"></i>
                </div>
                <div class="otlkToastMessage">{{errorMessage}}</div>
                <div class="otlkToastCloseAction">
                    <i class="gg-close otlkToastCloseActionIcon" (click)="closeToast()"></i>
                </div>
            </div>
        </div>
        <div class="ssoEnableBox" *ngIf="sso">
            <p>{{'Login.SSOContent' | translate}}</p><br>
            <button class="button ssoButton" (click) = "ssoLogin()">{{'Login.SSOButton' | translate}}
                <img src="../../../assets/ssoLogin.svg"/>
            </button><br>
            <div class="contactBox">
                <img src="../../../assets/information.svg"/> 
                <p>{{'Login.SSOContact' | translate}}</p>
            </div>
        </div>
    </div>
</div>