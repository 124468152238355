export class Message {    
    constructor(_applySamePoints: boolean, _comments: string, 
        _privateRecognition: boolean, _points?: number, _sendCopyToManager?: string, 
        _sendCopyToMe?: string, _sendCopyToOthers?: string) {
        this.applySamePoints = _applySamePoints;
        this.comments = _comments;
        this.privateRecognition = _privateRecognition;
        this.points = _points;
        this.sendCopyToManager = _sendCopyToManager;
        this.sendCopyToMe = _sendCopyToMe;
        this.sendCopyToOthers = _sendCopyToOthers
    }

    applySamePoints: boolean;
    points?: number;
    comments: string;
    privateRecognition: boolean;
    sendCopyToManager: string;
    sendCopyToMe: string;
    sendCopyToOthers: string;
}

export class Toast {       
    message : string
}