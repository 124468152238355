
export interface PromotionResponse {
    eligibleRecognitionPromotions: EligiblePromotion[];
    primaryNodeId: number;
    showRATab: boolean;
    imageUploadSize?: number;
    videoUploadSize?: number;
}

export interface EligiblePromotion {
    id: number;
    nodeId: number;
    nodeName: string;
    name: string;
    daysRemaining: number;
    awardAvailable: boolean;
    totalSent: number;
    lastSent: number;
    behaviorRequired: boolean;
    hasMultipleOrgBasedBudgets: boolean;
    awardType: string;
    awardMin?: number;
    awardMax?: number;
    awardFixed?: number;
    recognitionPrivateActive: boolean;
    allowYourOwnCard: boolean;
    ecardActive: boolean;
    badgeDetails: BudgetDetail[];
    budgetInfo: BudgetInfo;
    behaviors: Behaviors[];
    ecards: Ecard[];
    eCardCategory: EcardCategory[];
    copyManagerActive: boolean;
    copyManagerAlways: boolean;
    copyMeActive: boolean;
    copyOthersActive: boolean;
    showeCardsCategory?: boolean;
}

export interface BudgetInfo {
    budgetId: number;
    nodeId: number;
    originalValue: number;
    currentValue: number;
    daysRemaining: number;
}

export interface BudgetDetail {
    id: number;
    imgLarge: string;
    img: string;
    earned: boolean;
    progressNumerator: number;
    earnCount: number;
    type: string;
    name: string;
    howToEarnText: string;
}

export interface Behaviors {
    id: string;
    name: string;
    img: string;
}

export interface Ecard {
    id: number;
    name: string;
    smallImage: string;
    largeImage: string;
    cardType: string;
    canEdit: boolean;
    orderNumber: number;
    categoryType?: string;
}

export interface EcardCategory {
    id: number;
    name: string;
    sortOrder: number;
}

export class FileValidation {
    image?: number;
    video?: number
}

export interface ShopPoints {
    balance: number;
    showShopLink: boolean;
    showTravelLink: boolean;
    clientName: string;
    showMasteryLink: boolean;
}

export interface CatalogueResponse {
    token: string;
    url: string;
}

export interface CustomPromoResponse {
    token: string;
    url: string;
}