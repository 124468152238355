import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

import { AuthService } from '@service/auth.service'
import { Messages } from '@model/login-response.model';
import * as fromStore from '@app/store';
import * as fromTranslationAction from '@store/actions/translate.action';

import { faEye } from '@fortawesome/free-regular-svg-icons';

@Component({
    selector: 'login-page',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    faEye = faEye;
    public formLogin: FormGroup;
    public showPassword: boolean = false;
    public isSubmitted: boolean = false;
    public errorMessage: string;
    public disabled: boolean = false;
    public resetButton: boolean = true;
    public sso: boolean = false;
    public appcode: string

    constructor(private router: Router, private fb: FormBuilder, private authService: AuthService, private store: Store<fromStore.ProductState>
        , private translateService: TranslateService, private activatedRoute: ActivatedRoute) {
        this.formLogin = this.fb.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });
        this.resetButton = this.router.getCurrentNavigation().extras?.state?.reset;
        this.sso = this.router.getCurrentNavigation().extras?.state?.sso;
        
    }
    ngOnInit(){
        this.appcode = localStorage.getItem("appcode")
    }
    togglePassword() {
        this.showPassword = !this.showPassword;
        
    }
    ssoLogin() {
        var dialog;
        Office.context.ui.displayDialogAsync(`${environment.domain}/#/sso/${encodeURIComponent(localStorage.getItem('ssoUrl'))}`, { height: 50, width: 50, promptBeforeOpen: false }, (asyncResult) => {
            dialog = asyncResult.value;
            dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg) => {
                let response: any = JSON.parse(arg.message);
                dialog.close();
                if (response.success) {
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('culture', response.culture);
                    this.store.dispatch({ type: fromTranslationAction.CHANGE_LANGUAGE, payload: response.culture });
                    this.router.navigate([`/promotion`]);
                }
                else {
                    this.disabled = false;
                    this.errorMessage = response.error;
                    localStorage.clear();
                }
            });
        });
    }

    login() {
        this.errorMessage = '';
        this.isSubmitted = true;
        if (this.formLogin.valid) {
            this.disabled = true;
            this.authService.login(this.formLogin.value.username, this.formLogin.value.password).subscribe((response: Messages) => {
                if (response.code && response.code.toLowerCase() == "success") {
                    localStorage.setItem('token', response.text);
                    localStorage.setItem('culture', response.name);
                    this.store.dispatch({ type: fromTranslationAction.CHANGE_LANGUAGE, payload: response.name });
                    this.router.navigate([`/promotion`]);
                }
                else {
                    if (response.type === 'error' || response.type === 'serverCommand') {
                        this.translateService.get('Login.' + response.type + (response.command===undefined ? response.code : response.command)).subscribe((res) => {
                            this.errorMessage = res;
                        });
                    }
                    else {

                        this.translateService.get('errorCommon').subscribe((res) => {
                            this.errorMessage = res;
                        });
                    }

                }
                this.disabled = false;
            }, error => {
                this.errorMessage = error;
                this.disabled = false;
            });
        }
    }

    clearCache() {
        localStorage.clear();
        this.router.navigate([`/`]);
    }

    closeToast() {
        this.errorMessage = "";
    }
}